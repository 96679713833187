import React, { useState, useRef } from "react";
import QRCode from "qrcode.react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import "../styles/QRCodeStyle.css";

function QRCodeGenerator() {
  const [url, setUrl] = useState("");
  const [qrCodeValue, setQRCodeValue] = useState("");
  const qrCodeRef = useRef(null);

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const handleGenerateQRCode = () => {
    setQRCodeValue(url);
  };

  const handleDownloadQRCode = () => {
    domtoimage.toBlob(qrCodeRef.current).then(function (blob) {
      saveAs(blob, "qrcode.png");
    });
  };

  return (
    <div className="container">
      <h1 className="title">Free QR Code Generator</h1>
      <div className="content">
        <input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={handleChange}
          className="input"
        />
        <button onClick={handleGenerateQRCode} className="button">
          Generate QR Code
        </button>

        <div ref={qrCodeRef} className="qr-code">
          {qrCodeValue && <QRCode value={qrCodeValue} size={256} />}
        </div>
        {qrCodeValue && (
          <button onClick={handleDownloadQRCode} className="button">
            Download QR Code
          </button>
        )}
      </div>
    </div>
  );
}

export default QRCodeGenerator;
